import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from './Login';
import ForgotPwd from './ForgotPwd';
import Allitems from './CustomerLoggedin/Allitems';
import MyOrders from './CustomerLoggedin/MyOrders';
import AllOrderList from './OrderManagement/AllOrderList';
import OrderDetails from './OrderManagement/OrderDetails';
import CustomerDetails from './OrderManagement/CustomerDetails';
import ResetPassword from './resetPassword';
import './App.css';
import RedirectToHome from './RedirectToHome';
function App() {
	
  return (
  	
    <>
    	<Router>
    		<Switch>
    			<Route exact path="/" component={Login}></Route>
    			<Route exact path="/forgotPassword" component={ForgotPwd}></Route>
    			<Route exact path="/allitems" component={Allitems}></Route>
          <Route exact path="/myOrders" component={MyOrders}></Route>
          <Route exact path="/allOrderList" component={AllOrderList}></Route>
          <Route exact path="/customerDetails" component={CustomerDetails}></Route>
          <Route exact path="/orderDetails" component={OrderDetails}></Route>
          <Route exact path='/forgotPassword/token/:token' component={ResetPassword}></Route>
          <Route exact path='/logout' component={RedirectToHome}></Route>

    		</Switch>
    	</Router>
      
    </>
  );
}

export default App;
