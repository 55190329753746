import React, { Component } from "react";
import {Container, Col, Row, Form, Button } from 'react-bootstrap';
import { Formik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser,faLock,faLongArrowAltLeft,faEnvelope } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import { Link } from "react-router-dom";
export default class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMessage: "",
		};
	}
	componentDidMount() {
	}

	onSubmit = (submitData) => {
		const proxyurl = "https://blooming-lake-03275.herokuapp.com/";
		const headers = { "content-type": "application/vnd.api+json",'cache-control':'no-cache'};
		axios.post(proxyurl+"https://lonestar.sofmen.com/suitecrm_user/api/resetPassword", submitData, { headers }).then((response) => {
				if (response.data !== undefined) {
					if(response.data.status!==0){
						console.log('success',response.data)
						let navigateData={pathname:"/",state: { successMessage: response.data.message }}
						this.props.history.push(navigateData);
						
					}else{
						console.log(response.data)
						this.setState({ errorMessage:response.data.message})
					}
				
				}
			}).catch(error => {
				console.log(error)
			});
	};

	render(){
		return(
			<>
				<div className="login-wrap">	
					<Container>
						<Row>
							<Col xs md="8" className="m-auto">
								
								<div className="login-container">
									<div className="left-img">
										<div className="heading-content">
											<h2 className="text-center text-light">Lone<br/>Star</h2>
											<small>From Our Family To Yours!</small>
										</div>
									</div>
									<div className="right-form">
										<Formik className=""
									      	initialValues={{
												password: "",
												confirm_password: "",
												token:this.props.match.params.token
											}}
											validationSchema={Yup.object().shape({
												password: Yup.string().required("Please Enter New Password").min(8,'Please enter at least 8 characters'),
												confirm_password:Yup.string().oneOf([Yup.ref('password'), null], 'Both password need to be the same')
											})}
											onSubmit={(values) => {
												this.onSubmit(values);
											}}
									    >
									       {({
									         values,
									         errors,
									         touched,
									         handleChange,
									         handleBlur,
									         handleSubmit,
									         isSubmitting,
									         /* and other goodies */
									       }) => (
									        <form onSubmit={handleSubmit}>
									        	<h4 className="mb-4">Reset Password</h4>
												{this.state.errorMessage!==''
													&&
													<div className='alert alert-danger'>
														<label >
																{this.state.errorMessage}
														</label>
														<button type="submit" className='close close_forgot_pass' onClick={()=>this.setState({errorMessage:''})}>
															x					
														</button>
													</div>
												
												}
										        <div className="form-group form-gp">
											        <input
											            type="password"
											            name="password"
											            onChange={handleChange}
											            onBlur={handleBlur}
														value={values.username}
											            className="form-control"
											            placeholder="Password"
											        />
										            <div className="login-icon">
														<FontAwesomeIcon icon={faLock} />
													</div>
													<label className="err-msg">
										            	{errors.password && touched.password && errors.password}
										            </label>
										        </div>
										        <div className="form-group form-gp">
											        <input
											            type="password"
											            name="confirm_password"
											            onChange={handleChange}
											            onBlur={handleBlur}
											            value={values.confirm_password}
											            className="form-control"
														placeholder="Confirm Password"
											        />
										            <div className="login-icon">
														<FontAwesomeIcon icon={faLock} />
													</div>
													<label className="err-msg">
										           	{errors.confirm_password && touched.confirm_password && errors.confirm_password}
										           	</label>
										        </div>
												<div className="login-btn">
													<button
														type="submit"
														className="loginBtn"
													>
														Send
													
													</button>
												</div>
						
										        <div className="form-group form-gp">
										        	<Link to="/" className="forgot-link"><FontAwesomeIcon icon={faLongArrowAltLeft} /> Back</Link>
										        </div>
									        </form>
									       )}
									    </Formik>
								    </div>
							    </div>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		)
	}

}
