import Api  from "../api/Api";
export const authentication=(history)=>{
    const access_token =localStorage.getItem('access_token')
    const headers={'content-type':'application/vnd.api+json','Authorization': 'Bearer '+access_token,'Accept':'application/vnd.api+json','cache-control':'no-cache'}

	Api.get('V8/module/AOS_Products',{headers})
    .then(response => {
    	if (response.data !== undefined){
            console.log('authentication success')
        }
        else{
        localStorage.clear();
        history.push("/")
        }
    }).catch(error => {
        console.log('authentication failed')
        localStorage.clear();
        history.push("/")
    });
  }

  