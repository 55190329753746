import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {Container, Col, Row, Form, Button,Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { far } from '@fortawesome/free-regular-svg-icons';
import { faUser,faLock,faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import Api  from "./api/Api";
import * as Yup from "yup";

export default class Login extends Component{
	constructor(props) {
		super(props);
		this.state = {
			errorMessage:'',
			securityGroups:{'de788953-28d9-a5a8-4cf0-6033596472ec':'Order Processor','397a25f6-7e30-4fc4-c9fc-6021365f9c7b':'Customer'},
			isloading: false,
			successMessage: '',
			showSuccessMessage:true,
		};
	}
	componentDidMount() {
		this.loginAuthentication()
		if(this.props.location.state!==undefined){
			this.setState({successMessage:this.props.location.state.successMessage})
			setTimeout(() => {
				this.setState({successMessage:''})
			  }, 3000);
		
		}
		
	 }
	onSubmit =  (submitData) => {
		const data = {
			grant_type: "password",
			client_id:"9b4fb129-1afd-c270-adc8-601d266c892f",
			client_secret: "1234",
		};
		this.setState({isloading:true})
		data.username = submitData.username;
		data.password = submitData.password;
		const headers = { "content-type": "application/vnd.api+json",'cache-control':'no-cache'};
		 Api.post("access_token", data, { headers }).then((response) => {
			if (response.data !== undefined) {
				if (response.data.access_token !== undefined &&response.data.refresh_token !== undefined) {
					localStorage.setItem("access_token", response.data.access_token);
					localStorage.setItem("refresh_token", response.data.refresh_token);
					
				}
				

			}
			
		}).then(() => {
			const access_token =localStorage.getItem('access_token')
			const newHeaders={'content-type':'application/vnd.api+json','authorization': 'Bearer '+access_token,'Accept':'application/vnd.api+json','cache-control':'no-cache',}
			Api.get('V8/current-user',{  headers:newHeaders })
			.then(response => {
				if (response.data !== undefined){
					localStorage.setItem("user_data", JSON.stringify(response.data.data));

					}
					return Api.get('V8/module/Users/'+response.data.data.id+ '/relationships/SecurityGroups',{  headers:newHeaders })
			}).then(data => {
				if (data!== undefined){
					this.setState({isloading:false})
					if(data.data.data.length>0){
						let userTypeId =data.data.data[0].id
						 localStorage.setItem("user_type", JSON.stringify(this.state.securityGroups[userTypeId]));
		
							if(this.state.securityGroups[userTypeId]==='Customer'){
								this.props.history.push('/allitems')
							}
							if(this.state.securityGroups[userTypeId]==='Order Processor'){
								this.props.history.push('/allOrderList')
		
							}
					}else{
						this.setState({errorMessage:'Please Assign a Group To User',isloading:false})
					}
			
			
				}
			  })
			
		  }).catch(error => {
			if( error.response!==undefined){
				this.setState({ errorMessage: error.response.data.message,isloading:false });

			}
			
	});
	};			
	loginAuthentication=()=>{
		const userType=JSON.parse(localStorage.getItem('user_type'))
		if(userType!==null){
			if (userType==='Customer'){
				this.props.history.push('/allitems')
				}
			else{
				this.props.history.push('/allOrderList')
			}

			
		}
			
		}

	render(){
		return(
			<>
				<div className="login-wrap">	
					<Container>
						<Row>
							<Col xs md="8" className="m-auto">
								{this.state.isloading ?
									<div className="custom-loader"><Spinner animation="border" /></div>
									:
									<div className="login-container">
										<div className="left-img">
											<div className="heading-content">
												<h2 className="text-center text-light">Lone<br/> Star </h2>
												<small className="text-onimage">From Our Family To Yours!</small>
											</div>
										</div>
										<div className="right-form">
											{this.state.successMessage!=='' &&
												<div className={'alert alert-success'}>
												<label >
														{this.state.successMessage}
													</label>
													<button type="submit" className='close close_forgot_pass' onClick={()=>this.setState({successMessage:''})}>
														x
														
													</button>
											</div>

											}
											<Formik className=""
												initialValues={{ username: '', password: '' }}
												onSubmit={(values) => {
													this.onSubmit(values);
												}}
												validationSchema={Yup.object().shape({
													username: Yup.string().required("Please Enter User Name"),
													password: Yup.string().required("Please Enter Password"),
												})}
											
											>
											{({
												values,
												errors,
												touched,
												handleChange,
												handleBlur,
												handleSubmit,
												isSubmitting,
												/* and other goodies */
											}) => (
												<form onSubmit={handleSubmit}>
													<h4 className="mb-4">Login</h4>
													<div className="form-group form-gp">
														<input
															type="text"
															name="username"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.user}
															className="form-control"
															placeholder="User Name"
														/>
														<div className="login-icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
														<label className="err-msg">
															{errors.username && touched.username && errors.username}
														</label>
													</div>
													<div className="form-group form-gp">
														<input
															type="password"
															name="password"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.password}
															className="form-control"
															placeholder="Password"
														/>
														<div className="login-icon">
															<FontAwesomeIcon icon={faLock} />
														</div>
														<label className="err-msg">
															{errors.password && touched.password && errors.password}
														</label>
													</div>
													<label className="err-msg">
														{this.state.errorMessage}
													</label>
														<button
															type="submit"
															className="loginBtn"
														>
															Submit 
														</button>
													
													<div className="form-group form-gp">
														<Link to="/forgotPassword" className="forgot-link">Forgot password?</Link>
													</div>
												</form>
											)}
											</Formik>
										</div>
									</div>

								}
							</Col>
						</Row>
					</Container>
				</div>
			</>
		)
	}
}
