import React, {Component} from 'react';
import {Container, Col, Row, Card} from 'react-bootstrap';

export default class Banner extends Component{
	render(){
		return(
			<div className="footer-container">
				<Container>
					<Row>
						<Col md="12">
							<div className="text-center">© Copyright 2021. All right reserved.</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}