import React, { Component } from 'react'
import { Container, Row, Col, Card, Badge, Modal, Button, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleLineIcon from 'react-simple-line-icons';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Header from '../Header'
import Footer from '../Footer'
import Api from "../api/Api";
import { authentication } from "../authentication/tokenAuthentication";
import Moment from 'react-moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { decode, } from 'html-entities';
export default class OrderDetails extends Component {
	constructor(props) {
		super(props)
		const customerData = JSON.parse(localStorage.getItem('customer_data'))
		this.state = {
			data: props.location.state !== undefined && props.location.state.OrderDetails.order_items !== undefined ? props.location.state.OrderDetails.order_items : [],
			isloading: true,
			status: props.location.state !== undefined ? props.location.state.OrderDetails.status_c : []
		}
	}
	componentDidMount() {
		//this function check access token is valid or not 
		authentication(this.props.history)
		const userType = JSON.parse(localStorage.getItem('user_type'))
		if (userType === 'Customer') {
			this.props.history.push("/")
		}
		if (this.props.location.state !== undefined) {
			let orderItemsList = []
			const products = JSON.parse(localStorage.getItem('products'))
			//here we are adding product category in orderitemlist because we are not getting product category with orders and order items
			if (products !== null && this.state.data !== undefined) {
				this.state.data.map(orderItems => {
					let product = products.find(product => product.id == orderItems.attributes.order_order_item_aos_productsaos_products_ida)
					orderItems.category = product.attributes.category
					orderItemsList.push(orderItems)
				})
				this.setState({ data: orderItemsList, isloading: false })

			}
			console.log('order item', this.props.location.state.OrderDetails.order_items)

			this.getOrder()
		}
		else {
			this.props.history.push('/allOrderList')
		}
	}
	handleChange = (event) => {
		event.preventDefault();
		this.setState({ status: event.target.value })

		const customerData = JSON.parse(localStorage.getItem('customer_data'))
		const access_token = localStorage.getItem('access_token');
		let orderId = this.props.location.state.OrderDetails.id
		const headers = { 'content-type': 'application/vnd.api+json', 'Authorization': 'Bearer ' + access_token, 'Accept': 'application/vnd.api+json', 'cache-control': 'no-cache', }
		let attributes = { status_c: event.target.value }
		let newData = { type: 'order_order', attributes: attributes, id: orderId, }

		let fullData = { data: newData }
		Api.patch('V8/module', fullData, { headers })
			.then(response => {

				if (response.data !== undefined) {
					let orders = JSON.parse(localStorage.getItem('orders'))
					orders.map(order => {
						if (order.id === response.data.data.id) {
							order.status_c = event.target.value
						}

					})
					localStorage.setItem("orders", JSON.stringify(orders));
				}

			})
	}
	ItemFormatter = (cell, row) => {
		//here we have added condition for showing comma and value of item if it exist in order item 
		return (<div>{cell.case_quantity ? cell.case_quantity + ' Case' + (cell.half_case_quantity !== '' || cell.each_quantity !== '' || cell.pounds !== '' || cell.ounces_c !== '' ? ' , ' : '') : ''} {cell.half_case_quantity ? cell.half_case_quantity + ' Half Case' + (cell.each_quantity !== '' || cell.pounds !== '' || cell.ounces_c !== '' ? ' , ' : '') : ''}{cell.each_quantity ? cell.each_quantity + ' Each' + (cell.pounds !== '' || cell.ounces_c !== '' ? ' , ' : '') : ''}   {cell.pounds > 0 ? cell.pounds + ' Pounds' + (cell.ounces_c !== '' ? ' , ' : '') : ''}{cell.ounces_c > 0 ? cell.ounces_c + ' Ounces' : ''}</div>)
	}

	//this function fetch current order status and update it
	getOrder = () => {
		this.setState({ isloading: true })
		const access_token = localStorage.getItem('access_token')
		let OrderId = this.props.location.state.OrderDetails.id
		const headers = { 'content-type': 'application/vnd.api+json', 'Authorization': 'Bearer ' + access_token, 'Accept': 'application/vnd.api+json', 'cache-control': 'no-cache', }
		Api.get('V8/module/order_order/' + OrderId, { headers })
			.then(response => {
				if (response.data !== undefined) {


					this.setState({
						status: response.data.data.attributes.status_c,
						loading: false,
						allData: response.data.data,
						isloading: false

					})
				}
			});
	}

	render() {
		const customerData = JSON.parse(localStorage.getItem('customer_data'))
		const columns = [

			{
				dataField: 'attributes.order_order_item_aos_products_name',
				text: 'Item',
				sort: true
			},
			{
				dataField: 'category',
				text: 'Category',
				sort: true
			},
			{
				dataField: 'attributes',
				text: 'Number of items',
				sort: true,
				formatter: this.ItemFormatter,
			}
		];

		return (
			<>
				<Header history={this.props.history} />
				<div className="banner"><h2>Order Details - <Moment format="MMMM-YYYY">
					{customerData.date_entered}
				</Moment></h2></div>

				<section className="my-3">
					<Container>
						<Row>
							<Col md="12">
								<div className="mb-3 breadcrumb-route">
									<Link to="/allOrderList" className="">All Orders</Link> /
									<Link className="" to={{
										pathname: '/customerDetails',
										state: { customerDetails: customerData }
									}}> Customer Details </Link> /
									<a href="" className="text-active"> Order Details </a></div>
							</Col>
							<Col md="12">
								<Card>
									<Card.Body>
										{this.props.location.state !== undefined
											&&
											<div className="mb-4">
												<h5>Customer Details</h5>
												<div>{customerData.assigned_user_name}
												</div>
												<div>{decode(customerData.business_name)}
												</div>
												<div>{customerData.address}
												</div>
												<div>{customerData.phone_mobile}
												</div>
												<div>{customerData.email1}
												</div>
											</div>
										}
										<hr />
										<div className="heading-filter">
											<h5>Order Details: <small><Badge variant="success">{this.state.status}</Badge></small></h5>
											<div>
												<Row>
													<label>Update Status
													<select className="form-control" name="category" id="categori2" onChange={this.handleChange} value={this.state.status}>
															<option value="New">New</option>
															<option value="Processed">Processed</option>
															<option value="Cancelled">Cancelled</option>
														</select>
													</label>
												</Row>

											</div>
										</div>
										{this.state.isloading ?
											<div className="custom-loader"><Spinner animation="border" /></div>
											:
											<div className="table-responsive">
												<ToolkitProvider
													keyField="id"
													data={this.state.data}
													columns={columns}
												>
													{
														props => (
															<BootstrapTable {...props.baseProps} pagination={paginationFactory({ sizePerPage: 200, showTotal: true, hideSizePerPage: true })} />
														)

													}
												</ToolkitProvider>
											</div>
										}
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</section>
				<Footer />
			</>
		)
	}
} 