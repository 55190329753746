import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleLineIcon from 'react-simple-line-icons';
import Header from '../Header';
import Footer from '../Footer';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Api from "../api/Api";
import { authentication } from "../authentication/tokenAuthentication";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export default class Allitems extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			category: '',
			productName: '',
			searchErrorMessage: '',
			isloading: false,
			allData: [],
			orderCount: '',
			messageProduct: '',
			showError: false,
			messageMinimum: ''

		}
	}
	componentDidMount() {
		//this function check access token is valid or not 

		authentication(this.props.history)
		let MyOrderArray = JSON.parse(localStorage.getItem('MyOrderArray'))
		let allProducts = JSON.parse(localStorage.getItem('allProducts'))
		const userType = JSON.parse(localStorage.getItem('user_type'))
		if (userType === 'Order Processor') {
			this.props.history.push("/")
		}
		if (allProducts === null) {
			this.getProduct()
		}
		else {
			this.setState({ data: allProducts, allData: allProducts })
		}
		this.setState({ orderCount: MyOrderArray != null && MyOrderArray.length, })
	}
	handleChange = (event) => {
		event.preventDefault();
		if (event.target.value !== '') {
			let filterdData = this.state.allData.filter((item) => item.attributes.category === event.target.value)
			this.setState({ data: filterdData, })
		}
		else {
			this.setState({ data: this.state.allData, })
		}

	}
	getProduct = () => {
		this.setState({ isloading: true })
		const access_token = localStorage.getItem('access_token')
		const headers = { 'content-type': 'application/vnd.api+json', 'Authorization': 'Bearer ' + access_token, 'Accept': 'application/vnd.api+json', 'cache-control': 'no-cache', }
		Api.get('V8/module/AOS_Products?page[number]=1&page[size]=1000', { headers })
			.then(response => {
				if (response.data !== undefined) {
					response.data.data.map(product => {
						product.attributes.half_case_quantity = ''
						product.attributes.case_quantity = ''
						product.attributes.pounds = ''
						product.attributes.each_quantity = ''
						product.attributes.ounces_c = ''
					})
					localStorage.setItem("allProducts", JSON.stringify(response.data.data));
					localStorage.setItem("defaultProducts", JSON.stringify(response.data.data));
					this.setState({
						data: response.data.data,
						loading: false,
						allData: response.data.data,
						isloading: false

					})
				}
			});
	}

	logOut = () => {
		localStorage.clear();
		this.props.history.push('/')
	}

	allFormatter = (cell, row) => <span className="form-control">{cell}</span>
	poundFormatter = (cell, row) => {
		return (
			<div>
				<span className="form-control">{cell > 0 ? Number(cell) : cell}</span>
				<label className='help-text'>{!row.attributes.pounds > 0 &&
					row.attributes.minimum_lbs_c > 0 && 'Min ' + Number(row.attributes.minimum_lbs_c)}</label>
			</div>

		)
	}
	eachFormatter = (cell, row) => {
		return (
			<div>
				<span className="form-control">{cell}</span>
				<label className='help-text'>{!row.attributes.each_quantity > 0 && row.attributes.minimum_quantity_c > 0 && 'Min ' + row.attributes.minimum_quantity_c}</label>
			</div>

		)
	}
	ouncesFormatter = (cell, row) => {
		return (
			<div>
				<span className="form-control">{cell}</span>
				<label className='help-text'>{!row.attributes.ounces_c > 0 && row.attributes.minimum_ounces_c > 0 && 'Min ' + row.attributes.minimum_ounces_c}</label>
			</div>

		)
	}
	handleCellEdit = (oldValue, newValue, row, column) => {
		// console.log('handle cell Edit oldValue',oldValue)
		// console.log('handle cell Edit newValue', newValue)
		// console.log('handle cell Edit row',row)

		// console.log('handle cell Edit column', column)
		let MyOrderArray = []
		let orderCount = ''
		let showErrorMinimum = false
		//checking minimum for each 
		// if(column.text==='Each'){
		// 	if(newValue!==''&&newValue< (Math.floor(row.attributes.minimum_quantity_c))){

		// 		this.setState({messageMinimum:'Entered Value Should Be Greater Than Minimum Each Value ',messageProduct:''})
		// 		row.attributes.each_quantity=''
		// 		showErrorMinimum=true
		// 	}

		// }
		// //Checking minimum for pounds
		// if(column.text==='Pounds'){
		// 	if(newValue!==''&&newValue < (Math.floor(row.attributes.minimum_lbs_c))){

		// 		this.setState({messageMinimum:'Entered Value Should Be Greater Than Minimum  Pounds Value ',messageProduct:''})
		// 			row.attributes.pounds=''
		// 			showErrorMinimum=true
		// 	}

		// }
		// if value in not empty then we add the product in my orders
		if (row.attributes.case_quantity !== '' || row.attributes.half_case_quantity !== '' || row.attributes.each_quantity !== '' || row.attributes.pounds !== '' || row.attributes.ounces_c !== '') {
			if (localStorage.getItem('allProducts') !== null) {

				let allProducts = [...JSON.parse(localStorage.getItem('allProducts'))]
				var foundIndex = allProducts.findIndex(item => item.id == row.id);

				if (foundIndex >= 0) {

					allProducts[foundIndex] = row;
				}
				localStorage.setItem("allProducts", JSON.stringify(allProducts));
				if (localStorage.getItem('MyOrderArray') !== null) {

					MyOrderArray = [...JSON.parse(localStorage.getItem('MyOrderArray'))]
					var foundIndex = MyOrderArray.findIndex(item => item.id == row.id);
					//if order is already in myorder then we replace it with new values
					if (foundIndex >= 0) {

						MyOrderArray[foundIndex] = row;
					}
					orderCount = MyOrderArray.length
				}
				//here we are adding product in array if product is not already added in myorderarray
				let CheckItem = MyOrderArray.findIndex(item => item.id == row.id);
				if (CheckItem < 0) {
					toast.success("Product Added In My Order", {
						autoClose: 3000,
						hideProgressBar: true,
					});
					MyOrderArray.push(row)
				}
				localStorage.setItem("MyOrderArray", JSON.stringify(MyOrderArray));

				this.setState({ orderCount: MyOrderArray.length, messageProduct: showErrorMinimum ? '' : 'Product Added In My Order', showError: false })
			}

		} else {
			//here we are remove the elements from array if item is exist in my order array
			let MyOrderArray = []
			if (localStorage.getItem('MyOrderArray') !== null) {
				MyOrderArray = JSON.parse(localStorage.getItem('MyOrderArray'))
			}
			var foundIndex = MyOrderArray.findIndex(item => item.id == row.id);
			if (foundIndex >= 0) {
				MyOrderArray.splice(foundIndex, 1)
				localStorage.setItem("MyOrderArray", JSON.stringify(MyOrderArray));
				toast.success("Product removed from my order", {
					autoClose: 3000,
					hideProgressBar: true,
				});
				this.setState({ orderCount: MyOrderArray.length, showError: true })
			}
			if (localStorage.getItem('allProducts') !== null) {
				let allProducts = [...JSON.parse(localStorage.getItem('allProducts'))]
				var foundIndex = allProducts.findIndex(item => item.id == row.id);

				if (foundIndex >= 0) {

					allProducts[foundIndex] = row;
				}
				localStorage.setItem("allProducts", JSON.stringify(allProducts));

			}
		}
		setTimeout(() => {
			this.setState({ messageProduct: '', showError: false, messageMinimum: '' })
		}, 2000);


	}
	render() {
		const { SearchBar } = Search;
		const columns = [{
			dataField: 'attributes.name',
			text: 'Product Name',
			sort: true,
			headerStyle: {
				width: '10%',
			},
			editable: false,
		},
		{
			dataField: 'attributes.description',
			text: 'Description',
			sort: true,
			headerStyle: {
				width: '15%',

			},
			editable: false,
			style: { wordBreak: 'break-all' }
		},
		{
			dataField: 'attributes.category',
			text: 'Category',
			sort: true,
			headerStyle: {
				width: '7%',
			},
			editable: false,
		},
		{
			dataField: 'attributes.case_quantity',
			formatter: this.allFormatter,
			text: 'Case',
			sort: true,
			headerStyle: {
				width: '6%',
			},
			editable: (content, row, rowIndex, columnIndex) => row.attributes.item_case_c === '0' || row.attributes.available_c === '0' ? false : true,
			classes: (content, row, rowIndex, columnIndex) => row.attributes.item_case_c === '0' || row.attributes.available_c === '0' ? 'input-table disable' : 'input-table',

			validator: (newValue, row, column) => {
				if (isNaN(newValue)) {
					return {
						valid: false,
						message: 'Value should be numeric'
					};
				}
				if (newValue.search(/[.]/g) >= 0) {

					return {
						valid: false,
						message: 'Value should be without decimals'
					};

				}
				if (row.attributes.item_case_c === '0') {
					return {
						valid: false,
						message: 'Case Is Not Allowed For This Product',
						disable: true
					};
				}
				return true;
			},
		},
		{
			dataField: 'attributes.half_case_quantity',
			formatter: this.allFormatter,
			text: '1/2 Case',
			sort: true,
			headerStyle: {
				width: '6%',
			},
			classes: (content, row, rowIndex, columnIndex) => row.attributes.half_case_order_c === '0' || row.attributes.available_c === '0' ? 'input-table disable' : 'input-table',
			editable: (content, row, rowIndex, columnIndex) => row.attributes.half_case_order_c === '0' || row.attributes.available_c === '0' ? false : true,

			validator: (newValue, row, column) => {
				if (isNaN(newValue)) {
					return {
						valid: false,
						message: 'Value should be numeric'
					};
				}
				if (newValue.search(/[.]/g) >= 0) {

					return {
						valid: false,
						message: 'Value should be without decimals'
					};

				}
				if (row.attributes.half_case_order_c === '0') {
					return {
						valid: false,
						message: 'Half Case Is Not Allowed For This Product',
						disable: true
					};
				}
				return true;
			},

		},
		{
			dataField: 'attributes.each_quantity',
			formatter: this.eachFormatter,
			text: 'Each',
			sort: true,
			classes: 'input-table',
			headerStyle: {
				width: '6%',
			},
			classes: (content, row, rowIndex, columnIndex) => row.attributes.quantity_order_c === '0' || row.attributes.available_c === '0' ? 'input-table disable' : 'input-table',
			editable: (content, row, rowIndex, columnIndex) => row.attributes.quantity_order_c === '0' || row.attributes.available_c === '0' ? false : true,
			validator: (newValue, row, column) => {
				if (isNaN(newValue)) {
					return {
						valid: false,
						message: ' Value should be numeric'
					};
				}
				if (newValue.search(/[.]/g) >= 0) {
					return {
						valid: false,
						message: 'Value should be without decimals'
					};

				}
				// if (newValue==='') {
				// 	row.attributes.each_quantity=''
				// 		return {
				// 			valid: false,

				// 	};
				// }
				if (newValue != '' && newValue < (Math.floor(row.attributes.minimum_quantity_c))) {
					row.attributes.each_quantity = ''
					return {
						valid: false,
						message: 'Entered Value Should Be Greater Than Minimum Value'
					};
				}

				return true;
			},

		},

		{
			dataField: 'attributes.pounds',
			formatter: this.poundFormatter,
			text: 'Pounds',
			sort: true,
			headerStyle: {
				width: '6%',
			},
			classes: (content, row, rowIndex, columnIndex) => row.attributes.order_by_weight_c === '0' || row.attributes.available_c === '0' ? 'input-table disable' : 'input-table',
			editable: (content, row, rowIndex, columnIndex) => row.attributes.order_by_weight_c === '0' || row.attributes.available_c === '0' ? false : true,
			validator: (newValue, row, column) => {
				if (isNaN(newValue)) {
					return {
						valid: false,
						message: 'Value should be numeric'
					};
				}
				if (newValue.search(/[.]/g) >= 0) {
					return {
						valid: false,
						message: 'Value should be without decimals'
					};

				}
				if (newValue != '' && newValue < (Math.floor(row.attributes.minimum_lbs_c))) {
					row.attributes.pounds = ''

					return {
						valid: false,
						message: 'Entered Value Should Be Greater Than Minimum Value'
					};
				}
				return true;
			},

		},
		{
			dataField: 'attributes.ounces_c',
			formatter: this.ouncesFormatter,
			text: 'Ounces',
			sort: true,
			headerStyle: {
				width: '6%',
			},
			classes: (content, row, rowIndex, columnIndex) => row.attributes.order_by_ounces_c === false || row.attributes.order_by_ounces_c === '0' || row.attributes.available_c === '0' ? 'input-table disable' : 'input-table',
			editable: (content, row, rowIndex, columnIndex) => row.attributes.order_by_ounces_c === false || row.attributes.order_by_ounces_c === '0' || row.attributes.available_c === '0' ? false : true,
			validator: (newValue, row, column) => {
				if (isNaN(newValue)) {
					return {
						valid: false,
						message: 'Value should be numeric'
					};
				}
				if (newValue.search(/[.]/g) >= 0) {
					return {
						valid: false,
						message: 'Value should be without decimals'
					};

				}
				if (newValue != '' && newValue < (Math.floor(row.attributes.minimum_ounces_c))) {
					row.attributes.ounces_c = ''

					return {
						valid: false,
						message: 'Entered Value Should Be Greater Than Minimum Value'
					};
				}
				return true;
			},

		},
		];
		return (
			<>
				<Header orderCount={this.state.orderCount} history={this.props.history} />
				<ToolkitProvider
					keyField="id"
					data={this.state.data}
					columns={columns}
					search
				>
					{
						props => (
							<div>
								<div className="banner">
									<div className="search-container">
										<div className="hover_category">
											<select className="select_option" name="category" id="categori2" onChange={this.handleChange}>
												<option defaultValue="" value="">Select a Category</option>
												<option value="Fruit">Fruits</option>
												<option value="Vegetable">Vegetables</option>
												<option value="Herb">Herbs</option>
												<option value="Juice">Juice </option>
												<option value="Special">Special</option>
												<option value="Processed">Processed</option>
												<option value="Nuts">Nuts</option>
												<option value="Eggs">Eggs</option>
												<option value="Dairy">Dairy</option>
												<option value="Dried">Dried</option>
											</select>
										</div>
										<div className="search_box">
											<SearchBar {...props.searchProps} />
										</div>
									</div>
								</div>
								<section className="main-container">
									<Container>
										<Row>
											<Col md="12">
												<Card >
													<Card.Body>
														<h5 className="mb-4">All Items</h5>
														<div className="table-responsive">
															{this.state.isloading ?
																<div className="custom-loader"><Spinner animation="border" /></div>
																:
																<BootstrapTable {...props.baseProps}
																	noDataIndication={() => (<div>No Products Available</div>)}
																	pagination={paginationFactory({ sizePerPage: 200, showTotal: true, hideSizePerPage: true })}
																	cellEdit={cellEditFactory({
																		mode: 'click',
																		blurToSave: true,
																		afterSaveCell: (oldValue, newValue, row, column) => this.handleCellEdit(oldValue, newValue, row, column)

																	})}
																	classes='all-items-table'
																/>
															}
														</div>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</Container>
								</section>
							</div>

						)

					}
				</ToolkitProvider>

				<Footer />
			</>
		)
	}
} 