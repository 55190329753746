import React, { Component } from "react";
import {Spinner} from 'react-bootstrap';
export class RedirectToHome extends Component {
  constructor( props ){
    super();
    this.state = { ...props };
  }
  componentWillMount(){
    window.location = 'https://projects2.sofmen.com/produceordering/';
  }
  render(){
    return (<section><div className="custom-loader"><Spinner animation="border" /></div></section>);
  }
}

export default RedirectToHome;