import React, {Component} from 'react'
import {Container, Row, Col, Card,Spinner} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleLineIcon from 'react-simple-line-icons';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Header from '../Header'
import Footer from '../Footer'
import Api from "../api/Api";
import { authentication } from "../authentication/tokenAuthentication";
import axios from 'axios';
import Moment from 'react-moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'moment-timezone';
import {decode,} from 'html-entities';
export default class AllOrderList extends Component{
	constructor(props){
		super(props)
		this.state={
			orders:[],
			isloading:false,
			newData:[],
			orderItem:[],
			userNames:[],
			allOrders:[]
		}
	}
	componentDidMount() {
		//this function check access token is valid or not 
		authentication(this.props.history)
		const userType=JSON.parse(localStorage.getItem('user_type'))
		if(userType==='Customer'){
			this.props.history.push("/")
		}
		this.getOrders()
		this.getProduct()

	}

	NavigateCustomerDetails = (getRowData) =>{
		let toDetails = {pathname:'/customerDetails',state: { customerDetails: getRowData }}
		this.props.history.push(toDetails)
	} 
	
	getOrders = () => {
		this.setState({ isloading: true })
		const access_token = localStorage.getItem('access_token')
		const headers = { 'content-type': 'application/vnd.api+json', 'Authorization': 'Bearer ' + access_token, 'Accept': 'application/vnd.api+json', 'cache-control': 'no-cache', }
		let orders=[]
		let userIds=[]
		let users=[]
		let orderItems=[]
		//get all the order in database
		Api.get('V8/module/order_order?page[number]=1&page[size]=1000&sort=-date_entered', { headers }).then(response=>{
			
			if (response.data !== undefined) {
				let ordersResponse=response.data.data
				orders=ordersResponse.map( order =>{
					let orderNew=order.attributes;
					orderNew.id=order.id;
					return orderNew;
				})
				//get id of user who is created orders
				 userIds = [...new  Set (response.data.data.map( order => order.attributes.assigned_user_id))]
			}
			// get all the order items in database
			return 	Api.get('V8/module/order_order_item?page[number]=1&page[size]=1000', { headers })
		}).then(response => {
				let requestArrayUser=[]
				if (response.data !== undefined) {
				
					orderItems=response.data.data
					let orderIndex=''
					requestArrayUser=[]
					// get the details of user
					userIds.map((id)=>{
						if(id!==''){
						let requestUrlUser=Api.get('V8/module/User/'+id, { headers })
						requestArrayUser.push(requestUrlUser)
						}	
					})
					//here we are adding  new order items and order item count  key in orders 
					orderItems.map(orderitem=>{
						 orderIndex=orders.findIndex(order=>order.id==orderitem.attributes.order_order_order_order_itemorder_order_ida)
						 if(orderIndex >= 0){
							if(orders[orderIndex]['order_items']===undefined)
								orders[orderIndex]['order_items']=[];
							orders[orderIndex]['order_items'].push(orderitem);
							if(orders[orderIndex]['order_item_count']===undefined)
								orders[orderIndex]['order_item_count']=0;
							orders[orderIndex]['order_item_count']=orders[orderIndex]['order_items'].length;
						 }
						 
					})
					localStorage.setItem("orders", JSON.stringify(orders));
					

				}
				return    axios.all(requestArrayUser)

			}).then(axios.spread((...responses) => {
				let userIndex=''
				if (responses !== undefined) {
					users = responses.map(data => data.data.data)
					// here we are adding customers details in orders array
					let ordersnew=orders.map(order=>{
						userIndex=users.findIndex(user=>user.id==order.assigned_user_id)
						if(userIndex>=0){
							order.business_name=users[userIndex].attributes.business_name_c
							order.address=users[userIndex].attributes.address_c
							order.email1=users[userIndex].attributes.email1
							order.phone_mobile=users[userIndex].attributes.phone_mobile
						}
						return order;
					})		
					this.setState({orders:ordersnew,isloading:false,allOrders:ordersnew})
					
				}
			  })).catch(errors => {
				this.setState({isloading:false})
			  })
	}
	//fetching all the products
	getProduct = () => {
		const access_token = localStorage.getItem('access_token')
		const headers = { 'content-type': 'application/vnd.api+json', 'Authorization': 'Bearer ' + access_token, 'Accept': 'application/vnd.api+json', 'cache-control': 'no-cache', }
		Api.get('V8/module/AOS_Products?page[number]=1&page[size]=1000', { headers })
			.then(response => {
				if (response.data !== undefined) {
					localStorage.setItem("products", JSON.stringify(response.data.data));
					const userType=JSON.parse(localStorage.getItem('user_type'))
				}
			});
	}
	
	CustomerDetailFormatter = (cell, row) => {	return(<div><a  href=''onClick={()=>this.NavigateCustomerDetails(row)}>{cell}</a></div>)}
	 
	businessNameFormater=(cell, row)=>{return(<span>{decode(cell)}</span>)}

	dateFormatter = (cell, row) =>{
		let clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
		return(<div><Moment format="MM/DD/YYYY hh:mm:A" tz={clientTimezone} local={true}>{cell}</Moment></div>)
	}
	handleChange = (event) => {
		event.preventDefault();
		if (event.target.value !== '') {
			let filterdData = this.state.allOrders.filter((item) => item.status_c=== event.target.value)
			this.setState({ orders: filterdData ,})
		}
		else {
			this.setState({ orders: this.state.allOrders ,})
		}

	}

	render(){
		const columns = [
			{
				dataField: 'assigned_user_name',
				text: 'Customer Name',
				formatter: this.CustomerDetailFormatter,
				sort: true
			},
			{
				dataField: 'business_name',
				text: 'Business Name',
				formatter: this.businessNameFormater,
				sort: true
			},
			{
				dataField: 'status_c',
				text: 'Status',
				sort: true
			},
			{
				dataField: 'date_entered',
				text: 'Date Ordered',
				sort: true,
				formatter: this.dateFormatter,
			},
			{
				dataField: 'order_item_count',
				text: 'No. Of Items',
				sort: true,
				attrs: {
				  	align : 'right'
				},
				headerStyle: {
				  	textAlign : 'right'
				}
			}

		];
		
		return(
				<>
					<Header  history={this.props.history}/>
					<div className="banner">
						<h2>All Orders</h2>
					</div>
					<section>
						<Container>
							<Row>
								<Col md="12">
									<Card>
										<Card.Body>
										<div className="heading-filter">
												<div className='status_allorders'>
													<Row className='justify-content-end'>
													<Col md="2">
														<select className="form-control" name="category" id="categori2" onChange={this.handleChange}>
															<option value="">Select a Status</option>
															<option value="New">New</option>
															<option value="Processed">Processed</option>
															<option value="Cancelled">Cancelled</option>

														</select>
														</Col>
													</Row>
													
												</div>
											</div>
											{this.state.isloading ?
												<div className="custom-loader"><Spinner animation="border" /></div>
												:
												<div className="table-responsive">
													<ToolkitProvider
														keyField="id"
														data={this.state.orders}
														columns={ columns }
													>
													{
														props =>(
																<BootstrapTable { ...props.baseProps }  
																noDataIndication={() => (<div>No Order Available</div>)}  
																pagination={paginationFactory({ sizePerPage: 200, showTotal: true, hideSizePerPage: true })}
																sortName='date_entered'
																sortOrder='desc'
																/>
															)
														
													}
													</ToolkitProvider>
												</div>
											}
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Container>
					</section>
					<Footer />
				</>
			)
	}
} 