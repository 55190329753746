import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Card, Button, Spinner } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../Header';
import Footer from '../Footer';
import SimpleLineIcon from 'react-simple-line-icons';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { authentication } from "../authentication/tokenAuthentication";
import Api from "../api/Api";
import { AlertMessage } from "../alertBox/alertBox"
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export default class MyOrder extends Component {

	constructor(props) {
		super(props)


		this.state = {
			data: [],
			success: '',
			isDisable: false,
			messageProduct: '',
			showError: false,
			messageMinimum: '',
			showConfirmModal: false,
			isloading: false,
		}
	}
	componentDidMount() {
		//this function check access token is valid or not 
		authentication(this.props.history)
		let myOrdersArray = []
		const userType = JSON.parse(localStorage.getItem('user_type'))
		if (userType === 'Order Processor') {
			this.props.history.push("/")
		}
		if (JSON.parse(localStorage.getItem('MyOrderArray')) !== null) {
			myOrdersArray = JSON.parse(localStorage.getItem('MyOrderArray'))
			this.setState({ data: myOrdersArray })
		}
		let filterProducts = []
	}

	deleteProductFormatter = (cell, row, rowIndex) => <a href="" className="text-danger" title="Remove Order" onClick={() => this.removeMyOrder(row, rowIndex)}><SimpleLineIcon name="close" /></a>

	removeMyOrder = (row, rowIndex) => {
		let MyOrderArray = []
		if (localStorage.getItem('MyOrderArray') !== null) {
			MyOrderArray = JSON.parse(localStorage.getItem('MyOrderArray'))
		}
		MyOrderArray.splice(rowIndex, 1)
		localStorage.setItem("MyOrderArray", JSON.stringify(MyOrderArray));
		this.setState({ data: MyOrderArray })
		if (localStorage.getItem('allProducts') !== null) {

			let allProducts = [...JSON.parse(localStorage.getItem('allProducts'))]
			var foundIndex = allProducts.findIndex(item => item.id == row.id);

			if (foundIndex >= 0) {

				allProducts[foundIndex].attributes.case_quantity = ''
				allProducts[foundIndex].attributes.half_case_quantity = ''
				allProducts[foundIndex].attributes.each_quantity = ''
				allProducts[foundIndex].attributes.pounds = ''
				allProducts[foundIndex].attributes.ounces_c = ''
			}

			localStorage.setItem("allProducts", JSON.stringify(allProducts));
		}

	}
	onSubmitOrder = (data) => {
		const access_token = localStorage.getItem('access_token');
		const userData = JSON.parse(localStorage.getItem('user_data'))
		this.setState({ showConfirmModal: false, isDisable: true, isloading: true })
		let current_Date = new Date()
		let name = 'O' + current_Date.getTime()
		let responseDataOrder = []
		const headers = { 'content-type': 'application/vnd.api+json', 'Authorization': 'Bearer ' + access_token, 'Accept': 'application/vnd.api+json', 'cache-control': 'no-cache', }
		let attributes = { assigned_user_id: userData.id, assigned_user_name: userData.user_name, name: name }
		let newData = { type: 'order_order', attributes: attributes }

		let fullData = { data: newData }
		Api.post('V8/module', fullData, { headers })
			.then(response => {
				if (response.data !== undefined) {
					responseDataOrder = response.data.data
				}

				return response
			}).then((response) => {
				const newheaders = { 'content-type': 'application/vnd.api+json', 'Authorization': 'Bearer ' + access_token, 'Accept': 'application/vnd.api+json', 'cache-control': 'no-cache', }
				let requestOrderItems = []
				let requestUrlOrderItem = ''
				this.state.data.map(item => {
					let attributes = { case_quantity: item.attributes.case_quantity, half_case_quantity: item.attributes.half_case_quantity, pounds: item.attributes.pounds, each_quantity: item.attributes.each_quantity, assigned_user_id: userData.id, order_order_item_aos_productsaos_products_ida: item.id, order_order_order_order_itemorder_order_ida: response.data.data.id, ounces_c: item.attributes.ounces_c }

					let newData = { type: 'order_order_item', attributes: attributes }
					let fullData = { data: newData }
					requestUrlOrderItem = Api.post('V8/module', fullData, { headers: newheaders })
					requestOrderItems.push(requestUrlOrderItem)

				})
				return axios.all(requestOrderItems)
			}).then(axios.spread((...responses) => {
				const proxyurl = "https://blooming-lake-03275.herokuapp.com/";
				let emailData = { order_id: responseDataOrder.id, email_id: userData.attributes.email1 }
				return axios.post(proxyurl + 'https://lonestar.sofmen.com/suitecrm_user/api/order_email', emailData, { headers })
			})

			).then(responseEmail => {
				// toast.success("Order created successfully",{autoClose: 3000,
				// 	hideProgressBar: true,});
				this.setState({ data: [], isDisable: false, isloading: false })
				let allProducts = JSON.parse(localStorage.getItem('defaultProducts'))
				localStorage.setItem("allProducts", JSON.stringify(allProducts));
				localStorage.removeItem('MyOrderArray')
			}).catch(error => {
				this.setState({ isloading: false, showError: true, messageProduct: 'Something Went Wrong' })
			});
	}
	allFormatter = (cell, row) => <span className="form-control">{cell}</span>
	poundFormatter = (cell, row) => {
		return (
			<div>
				<span className="form-control">{cell > 0 ? Number(cell) : cell}</span>
				<label className='help-text'>{!row.attributes.pounds > 0 &&
					row.attributes.minimum_lbs_c > 0 && 'Min ' + Number(row.attributes.minimum_lbs_c)}</label>
			</div>

		)
	}
	eachFormatter = (cell, row) => {
		return (
			<div>
				<span className="form-control">{cell}</span>
				<label className='help-text'>{!row.attributes.each_quantity > 0 && row.attributes.minimum_quantity_c > 0 && 'Min ' + row.attributes.minimum_quantity_c}</label>
			</div>
		)
	}
	ouncesFormatter = (cell, row) => {
		return (
			<div>
				<span className="form-control">{cell}</span>
				<label className='help-text'>{!row.attributes.ounces_c > 0 && row.attributes.minimum_ounces_c > 0 && 'Min ' + row.attributes.minimum_ounces_c}</label>
			</div>
		)
	}
	handleCellEdit = (oldValue, newValue, row, column) => {
		let MyOrderArray = []
		let orderCount = ''
		let showErrorMinimum = false
		//checking minimum for each 
		if (column.text === 'Each') {
			if (newValue !== '' && newValue < (Math.floor(row.attributes.minimum_quantity_c))) {

				this.setState({ messageMinimum: 'Entered Value Should Be Greater Than Minimum Each Value ', messageProduct: '' })
				row.attributes.each_quantity = ''
				showErrorMinimum = true
			}

		}
		//Checking minimum for pounds
		if (column.text === 'Pounds') {
			if (newValue !== '' && newValue < (Math.floor(row.attributes.minimum_lbs_c))) {

				this.setState({ messageMinimum: 'Entered Value Should Be Greater Than Minimum  Pounds Value ', messageProduct: '' })
				row.attributes.pounds = ''
				showErrorMinimum = true
			}

		}
		// if value in not empty then we add the product in my orders
		if (row.attributes.case_quantity !== '' || row.attributes.half_case_quantity !== '' || row.attributes.each_quantity !== '' || row.attributes.pounds !== '' || row.attributes.ounces_c !== '') {
			if (localStorage.getItem('allProducts') !== null) {

				let allProducts = [...JSON.parse(localStorage.getItem('allProducts'))]
				var foundIndex = allProducts.findIndex(item => item.id == row.id);

				if (foundIndex >= 0) {

					allProducts[foundIndex] = row;
				}
				localStorage.setItem("allProducts", JSON.stringify(allProducts));
				if (localStorage.getItem('MyOrderArray') !== null) {

					MyOrderArray = [...JSON.parse(localStorage.getItem('MyOrderArray'))]
					var foundIndex = MyOrderArray.findIndex(item => item.id == row.id);
					//if order is already in myorder then we replace it with new values
					if (foundIndex >= 0) {

						MyOrderArray[foundIndex] = row;
					}
					orderCount = MyOrderArray.length
				}
				//here we are adding product in array if product is not already added in myorderarray
				let CheckItem = MyOrderArray.findIndex(item => item.id == row.id);
				if (CheckItem < 0) {
					toast.success("Product added in my order", {
						autoClose: 3000,
						hideProgressBar: true,
					});
					MyOrderArray.push(row)
				}
				localStorage.setItem("MyOrderArray", JSON.stringify(MyOrderArray));
				this.setState({ data: MyOrderArray })

				this.setState({ orderCount: MyOrderArray.length, showError: false })
			}

		} else {
			//here we are remove the elements from array if item is exist in my order array
			let MyOrderArray = []
			if (localStorage.getItem('MyOrderArray') !== null) {
				MyOrderArray = JSON.parse(localStorage.getItem('MyOrderArray'))
			}
			var foundIndex = MyOrderArray.findIndex(item => item.id == row.id);
			if (foundIndex >= 0) {
				MyOrderArray.splice(foundIndex, 1)
				localStorage.setItem("MyOrderArray", JSON.stringify(MyOrderArray));
				toast.success("Product removed from my order", {
					autoClose: 3000,
					hideProgressBar: true,
				});
				this.setState({ orderCount: MyOrderArray.length, showError: true })
				this.setState({ data: MyOrderArray })
			}
			if (localStorage.getItem('allProducts') !== null) {
				let allProducts = [...JSON.parse(localStorage.getItem('allProducts'))]
				var foundIndex = allProducts.findIndex(item => item.id == row.id);

				if (foundIndex >= 0) {

					allProducts[foundIndex] = row;
				}
				localStorage.setItem("allProducts", JSON.stringify(allProducts));

			}
		}
		setTimeout(() => {
			this.setState({ messageProduct: '', showError: false, messageMinimum: '' })
		}, 2000);


	}
	CloseModal = () => {
		this.setState({ showConfirmModal: !this.state.showConfirmModal })
	}
	render() {
		const columns = [{
			dataField: 'attributes.name',
			text: 'Product Name',
			sort: true,
			headerStyle: {
				width: '10%',
			},
			editable: false,
		},
		{
			dataField: 'attributes.description',
			text: 'Description',
			sort: true,
			headerStyle: {
				width: '15%',

			},
			editable: false,
			style: { wordBreak: 'break-all' }
		},
		{
			dataField: 'attributes.category',
			text: 'Category',
			sort: true,
			headerStyle: {
				width: '7%',
			},
			editable: false,
		},
		{
			dataField: 'attributes.case_quantity',
			formatter: this.allFormatter,
			text: 'Case',
			sort: true,
			headerStyle: {
				width: '6%',
			},
			editable: (content, row, rowIndex, columnIndex) => row.attributes.item_case_c === '0' ? false : true,
			classes: (content, row, rowIndex, columnIndex) => row.attributes.item_case_c === '0' ? 'input-table disable' : 'input-table',

			validator: (newValue, row, column) => {
				if (isNaN(newValue)) {
					return {
						valid: false,
						message: 'Value should be numeric'
					};
				}
				if (newValue.search(/[.]/g) >= 0) {
					return {
						valid: false,
						message: 'Value should be without decimals'
					};

				}
				if (row.attributes.item_case_c === '0') {
					return {
						valid: false,
						message: 'Case Is Not Allowed For This Product',
						disable: true
					};
				}
				return true;
			},
		},
		{
			dataField: 'attributes.half_case_quantity',
			formatter: this.allFormatter,
			text: '1/2 Case',
			sort: true,
			headerStyle: {
				width: '6%',
			},
			classes: (content, row, rowIndex, columnIndex) => row.attributes.half_case_order_c === '0' ? 'input-table disable' : 'input-table',
			editable: (content, row, rowIndex, columnIndex) => row.attributes.half_case_order_c === '0' ? false : true,

			validator: (newValue, row, column) => {
				if (isNaN(newValue)) {
					return {
						valid: false,
						message: 'Value should be numeric'
					};
				}
				if (newValue.search(/[.]/g) >= 0) {
					return {
						valid: false,
						message: 'Value should be without decimals'
					};
				}
				if (row.attributes.half_case_order_c === '0') {
					return {
						valid: false,
						message: 'Half Case Is Not Allowed For This Product',
						disable: true
					};
				}
				return true;
			},
		},
		{
			dataField: 'attributes.each_quantity',
			formatter: this.eachFormatter,
			text: 'Each',
			sort: true,
			headerStyle: {
				width: '6%',
			},
			classes: (content, row, rowIndex, columnIndex) => row.attributes.quantity_order_c === '0' ? 'input-table disable' : 'input-table',
			editable: (content, row, rowIndex, columnIndex) => row.attributes.quantity_order_c === '0' ? false : true,

			validator: (newValue, row, column) => {
				if (isNaN(newValue)) {
					return {
						valid: false,
						message: ' Value should be numeric'
					};
				}
				if (newValue.search(/[.]/g) >= 0) {
					return {
						valid: false,
						message: 'Value should be without decimals'
					};

				}
				// if (newValue==='') {
				// 	row.attributes.each_quantity=''
				// 		return {
				// 			valid: false,			
				// 	};
				// }
				if (newValue != '' && newValue < (Math.floor(row.attributes.minimum_quantity_c))) {
					row.attributes.each_quantity = ''
					return {
						valid: false,
						message: 'Entered Value Should Be Greater Than Minimum Value'
					};
				}
				return true;
			},
		},

		{
			dataField: 'attributes.pounds',
			formatter: this.poundFormatter,
			text: 'Pounds',
			sort: true,
			headerStyle: {
				width: '6%',
			},
			classes: (content, row, rowIndex, columnIndex) => row.attributes.order_by_weight_c === '0' ? 'input-table disable' : 'input-table',
			editable: (content, row, rowIndex, columnIndex) => row.attributes.order_by_weight_c === '0' ? false : true,
			validator: (newValue, row, column) => {
				if (isNaN(newValue)) {
					return {
						valid: false,
						message: 'Value should be numeric'
					};
				}
				if (newValue.search(/[.]/g) >= 0) {
					return {
						valid: false,
						message: 'Value should be without decimals'
					};

				}
				if (newValue != '' && newValue < (Math.floor(row.attributes.minimum_lbs_c))) {
					row.attributes.pounds = ''

					return {
						valid: false,
						message: 'Entered Value Should Be Greater Than Minimum Value'
					};
				}
				return true;
			},

		},
		{
			dataField: 'attributes.ounces_c',
			formatter: this.ouncesFormatter,
			text: 'Ounces',
			sort: true,
			headerStyle: {
				width: '6%',
			},
			classes: (content, row, rowIndex, columnIndex) => row.attributes.order_by_ounces_c === false ? 'input-table disable' : 'input-table',
			editable: (content, row, rowIndex, columnIndex) => row.attributes.order_by_ounces_c === false ? false : true,
			validator: (newValue, row, column) => {
				if (isNaN(newValue)) {
					return {
						valid: false,
						message: 'Value should be numeric'
					};
				}
				if (newValue.search(/[.]/g) >= 0) {
					row.attributes.ounces_c = ''

					return {
						valid: false,
						message: 'Value should be without decimals'
					};

				}
				if (newValue != '' && newValue < (Math.floor(row.attributes.minimum_ounces_c))) {
					row.attributes.ounces_c = ''

					return {
						valid: false,
						message: 'Entered Value Should Be Greater Than Minimum Value'
					};
				}
				return true;
			},

		},
		{
			dataField: '',
			text: '',
			formatter: this.deleteProductFormatter,
			editable: false,
			attrs: {
				align: 'center'
			},
			style: { verticalAlign: 'middle' },
			headerStyle: {
				width: '4%',
				textAlign: 'center'
			}
		}
		];
		return (
			<>
				<Header history={this.props.history} />
				<div className="banner">
					<h2>My Order</h2>
				</div>
				<section>
					<Container>
						<Row>
							<Col md="12">
								<Card>
									<Card.Body>
										<div className="table-responsive">
											<ToolkitProvider
												keyField="id"
												data={this.state.data}
												columns={columns}


											>
												{
													props => (
														this.state.isloading ?
															<div className="custom-loader"><Spinner animation="border" /></div>
															:
															<BootstrapTable {...props.baseProps}
																noDataIndication={() => (<div>Your order has been submitted.</div>)}
																cellEdit={cellEditFactory({
																	mode: 'click',
																	blurToSave: true,
																	afterSaveCell: (oldValue, newValue, row, column) => this.handleCellEdit(oldValue, newValue, row, column)

																})}
																pagination={paginationFactory({ sizePerPage: 200, showTotal: true, hideSizePerPage: true })}
																classes='all-items-table'

															/>
													)

												}
											</ToolkitProvider>
										</div>

										<div className="float-right my-3">

											<Button className="text-right submit-btn" disabled={this.state.data.length > 0 && !this.state.isDisable ? false : true} onClick={() => this.setState({ showConfirmModal: true })}>Submit Order</Button>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						<Row>
							<Modal isOpen={this.state.showConfirmModal} backdrop={true} toggle={this.CloseModal} className='Confirmation-modal' >
								<ModalHeader className='align-items-center'>
									<h2>Submit Order</h2>

								</ModalHeader>
								<ModalBody>
									<Row >
										<Col md="12">
											<label>
												Are you sure you want to submit your order?</label>

										</Col>
									</Row>
								</ModalBody>

								<ModalFooter>
									<Button onClick={() => this.setState({ showConfirmModal: false })} variant="secondary">No</Button>
									<Button className='button-success' onClick={this.onSubmitOrder}>Yes</Button>
								</ModalFooter>
							</Modal>


						</Row>
					</Container>
				</section>
				<Footer />
			</>
		)
	}
} 