import React, {Component} from 'react'
import {Container, Row, Col, Card, Modal, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleLineIcon from 'react-simple-line-icons';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik, Field, Form } from 'formik';
import Header from '../Header'
import Footer from '../Footer'
import { authentication } from "../authentication/tokenAuthentication";
import Moment from 'react-moment';
import Api from "../api/Api";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {decode,} from 'html-entities';
export default class CustomerDetalis extends Component{
	constructor(props){
		super(props)
		this.state={
			orderData:[],
			data:[],
			orders:JSON.parse(localStorage.getItem('orders')),
		}
	}
	componentDidMount() {
		//this function check access token is valid or not
		authentication(this.props.history)
		const userType=JSON.parse(localStorage.getItem('user_type'))
		if(userType==='Customer'){
			this.props.history.push("/")
		}
		if(this.props.location.state!==undefined){
			let allOrders=[]

			if(this.state.orders!==null){
				this.state.orders.map(order=>{
					if(order.assigned_user_id ===this.props.location.state.customerDetails.assigned_user_id){
					   allOrders.push(order)
					}
				})
				
			}
			this.setState({data:allOrders})
		}
		else{
			this.props.history.push('/allOrderList')
		}
		console.log('customer details',)
		
		
		
	}
	handleModalshowHide(){
		this.setState({ showHide: !this.state.showHide })
	}
	// getOrders = () => {
	// 	this.setState({ isloading: true })
	// 	const customerData=this.props.location.state.customerDetails
	// 	const access_token = localStorage.getItem('access_token')
	// 	const headers = { 'content-type': 'application/vnd.api+json', 'Authorization': 'Bearer ' + access_token, 'Accept': 'application/vnd.api+json', 'cache-control': 'no-cache', }
	// 	let orders=[]
	// 	let userIds=[]
	// 	let users=[]
	// 	let orderItems=[]
	// 	Api.get('V8/module/order_order?filter[assigned_user_id][eq]='+customerData.assigned_user_id, { headers }).then(response=>{
	// 		let OrderItemIds=[]
	// 		let orders=[]
	// 		if (response.data !== undefined) {
				
				

		
	// 		}
	// 		// ?filter[id][eq]=664e8d3f-1996-a08f-40d8-602f943c6370,f3ffadea-462c-dd54-1318-6036412a6b6f
	// 	})
	// }

	getOrderDetail(rowDetail){
		localStorage.setItem("customer_data", JSON.stringify(this.props.location.state.customerDetails));
		let toDetails = {pathname:'/orderDetails',state: { OrderDetails: rowDetail }}
		this.props.history.push(toDetails)
	}

	orderLink = (cell, row) => {
		let clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone 
		return(
		<a  href='' onClick={() => this.getOrderDetail(row)}  ><Moment format="MM/DD/YYYY hh:mm:A" tz={clientTimezone}>
		{cell}
		</Moment></a>
	)
}


dateSorter=(a, b)=> {
	var d1 = new Date(a);
	var d2 = new Date(b);
  
	if (d1 < d2) return -1;
	if (d1 > d2) return 1;
	return 0;
  }
	render(){
		const columns = [
			{
				dataField: 'date_entered',
				text: 'Date Ordered',
				formatter: this.orderLink,
				sort: true,
				
			},
			{
				dataField: 'status_c',
				text: 'Status',
				sort: true
			},
			{
				dataField: 'order_item_count',
				text: 'No. Of Items',
				sort: true,
				attrs: {
				  	align : 'right'
				},
				headerStyle: {
				  	width : '15%'
				}
			}
		];
		
		return(
				<>
					<Header  history={this.props.history}/>
					<div className="banner">
					<h2>Customer Details</h2>
					</div>

					<section className="my-3">
						<Container>
							
							<Row>
								<Col md="12">
									<div className="mb-3 breadcrumb-route"><Link to="/allOrderList" className="">All Orders </Link>/ <a href="" className="text-active"> Customer Details</a></div>
								</Col>
								<Col md="12">
									<Card>
										<Card.Body>
											{this.props.location.state!==undefined
												&&
												<div className="mb-4">
												<h5 className="mb-4">Customer Details</h5>
												<div>{this.props.location.state.customerDetails.assigned_user_name}
												</div>
												<div>{decode(this.props.location.state.customerDetails.business_name)}
												</div>
												<div>
												{this.props.location.state.customerDetails.address}
												</div>
												<div>{this.props.location.state.customerDetails.phone_mobile}
												</div>
												<div>{this.props.location.state.customerDetails.email1}
												</div>
											</div>
											}
											
											<hr />
											<h5 className="mb-4">Orders </h5>
											<div className="table-responsive">
												<ToolkitProvider
													keyField="id"
													data={this.state.data}
													columns={ columns }
												>
												{
												   	props =>(
															<BootstrapTable { ...props.baseProps }  pagination={paginationFactory({ sizePerPage: 200, showTotal: true, hideSizePerPage: true })} />
												   		)
												      
												}
												</ToolkitProvider>
											</div>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Container>
					</section>
					<Modal show={this.state.showHide}>
				        <Modal.Header closeButton onClick={()=> this.handleModalshowHide()}>
				          <Modal.Title >Edit <span className="text-active">Details</span></Modal.Title>
				        </Modal.Header>
				        <Modal.Body>
				        	<Formik
						      initialValues={{
						        firstName: '',
						        lastName: '',
						        email: '',
						      }}
						      onSubmit={async (values) => {
						        await new Promise((r) => setTimeout(r, 500));
						        alert(JSON.stringify(values, null, 2));
						      }}
						    >
						      <Form>
						        <label htmlFor="firstName">Customer Name</label>
						        <Field id="firstName" className="form-control mb-2" name="firstName" placeholder="" />

						        <label htmlFor="lastName">Business Name</label>
						        <Field id="lastName" className="form-control mb-2" name="lastName" placeholder="" />

						        <label htmlFor="firstName">Address</label>
						        <Field id="addrs" className="form-control mb-2" name="address" placeholder="" />

						        <label htmlFor="lastName">Phone Number</label>
						        <Field id="phone" className="form-control mb-2" name="phone" placeholder="Doe" />

						        <label htmlFor="email">Email</label>
						        <Field id="email" className="form-control mb-2" name="email" placeholder="jane@acme.com" type="email"
						        />
						      </Form>
						    </Formik>
				        </Modal.Body>
				        <Modal.Footer>
				          <button className="submit-btn-outline"  onClick={()=> this.handleModalshowHide()}>
				            Close
				          </button>
				          <button className="submit-btn"  onClick={()=> this.handleModalshowHide()}>
				            Save Changes
				          </button>
				        </Modal.Footer>
				    </Modal>
					<Footer />
				</>
			)
	}
} 