import React, {Component} from 'react';
import {Router, Route, NavLink, Link } from 'react-router-dom';
import {Container, Col, Row, Navbar, Nav, NavDropdown ,Badge} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCart} from '@fortawesome/free-solid-svg-icons';
import SimpleLineIcon from 'react-simple-line-icons';
import logo from './assets/images/logo.png';
export default class Header extends Component{

	logOut = () => {
		localStorage.clear();
	this.props.history.push('/logout')

		
	}
	render(){
		const userData=JSON.parse(localStorage.getItem('user_data'))
		const userType=JSON.parse(localStorage.getItem('user_type'))
		return(
			<>
				<header>
					<Container>
						<Row>
							<Col md="12">
								<nav className="navbar navbar-expand-lg">
								  <a className="header-logo" ><img src={logo} alt="Logo"/></a>
								  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
								    <FontAwesomeIcon icon={faBars} />
								  </button>
								  <div className="collapse navbar-collapse" id="navbarNav">
								    <ul className="navbar-nav ml-auto">
										{userType==='Customer'
											?
											<Row>
												<li className="nav-item">
													<NavLink className="nav-link" to="/allitems" activeStyle={{color: "#4485ef", transition: "all 0.3s ease 0s", border: "1px solid"}}>All Items</NavLink>
												</li>
												<li className="nav-item">
													<NavLink className="nav-link" to={{pathname:"/myOrders"}} activeStyle={{color: "#4485ef", transition: "all 0.3s ease 0s", border: "1px solid"}}>My Order {this.props.orderCount >0 &&<Badge variant="primary">{this.props.orderCount}</Badge>} </NavLink>
												</li>
											</Row>
										
											:<li className="nav-item">
												<NavLink className="nav-link" to="/allOrderList" activeStyle={{color: "#4485ef", transition: "all 0.3s ease 0s", border: "1px solid"}}>All Orders</NavLink>
											</li> 	
										}
								      	<li className="nav-item dropdown">
									        <a className="nav-link dropdown-toggle" href="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									        {userData!==null && userData.attributes.full_name} &nbsp;
									        </a>
									        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
									          <a className="nav-link d-flex justify-content-between align-items-center" href=""  onClick={this.logOut}>Logout <SimpleLineIcon name="power"/></a>
								        	</div>
								      	</li>

								    </ul>
								  </div>
								</nav>
							</Col>
						</Row>
					</Container>
				</header>
				
			</>
			)
	}
} 